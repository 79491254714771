const sketch = (darkMode) => (p) => {
  let canvasWidth;
  let canvasHeight;
  let gridWidth;
  let gridHeight = 2000; // Static height for grid, can be dynamic like width if needed
  let numberOfColumns;
  let numberOfRows;
  let terrainHeights;
  let xScale = 1.5;
  let flightPosition = 500;
  const triangleSize = 40;
  const flightSpeed = 0.0015;
  const mountainHeight = 150;

  p.setup = () => {
    canvasWidth = p.windowWidth * xScale;
    canvasHeight = p.windowHeight; // or a fixed value if preferred
    gridWidth = canvasWidth;
    numberOfColumns = gridWidth / triangleSize;
    numberOfRows = gridHeight / triangleSize;
    terrainHeights = Array.from({ length: numberOfColumns }, () =>
      new Array(numberOfRows).fill(0)
    );

    p.createCanvas(canvasWidth, canvasHeight, p.WEBGL);
    p.background(darkMode ? "#000" : "#FFF"); // Update based on dark mode
    // Set the frame rate
    p.frameRate(30);
  };

  p.draw = () => {
    flightPosition -= flightSpeed; // Reset each draw call if not animated over time
    p.background(darkMode ? "#000" : "#FFF"); // Update based on dark mode
    p.rotateX(1.4);
    p.translate((-canvasWidth * xScale) / 2, -canvasHeight / 6, -10);
    drawMountains(p, flightPosition);
  };

  p.windowResized = () => {
    canvasWidth = p.windowWidth;
    canvasHeight = p.windowHeight; // or fixed, to match setup
    gridWidth = canvasWidth;
    numberOfColumns = gridWidth / triangleSize;
    numberOfRows = gridHeight / triangleSize;
    terrainHeights = Array.from({ length: numberOfColumns }, () =>
      new Array(numberOfRows).fill(0)
    );

    p.resizeCanvas(canvasWidth, canvasHeight);
  };

  function drawMountains(p, flightPosition) {
    let yOffset = flightPosition;
    p.stroke("#46278F ");
    p.strokeWeight(0.3);
    for (let row = 0; row < numberOfRows - 1; row++) {
      p.beginShape(p.TRIANGLE_STRIP);
      let xOffset = 0;
      for (let column = 0; column < numberOfColumns; column++) {
        // Ensure column array exists before attempting to access it
        terrainHeights[column] =
          terrainHeights[column] || new Array(numberOfRows).fill(0);

        terrainHeights[column][row] = p.map(
          p.noise(xOffset, yOffset),
          0,
          1,
          0,
          mountainHeight
        );
        let vertexX = column * triangleSize;
        let vertexY = row * triangleSize;
        p.fill(
          terrainHeights[column][row]
            ? darkMode
              ? "#000"
              : "#FFF"
            : darkMode
            ? "#241734"
            : "#EEE"
        ); // Fill based on height and dark mode
        p.vertex(vertexX, vertexY, terrainHeights[column][row]);
        p.vertex(
          vertexX,
          vertexY + triangleSize,
          terrainHeights[column][row + 1] ? terrainHeights[column][row + 1] : 0
        );
        xOffset += triangleSize / 100;
      }
      yOffset += triangleSize / 100;
      p.endShape();
    }
  }
};

export default sketch;
