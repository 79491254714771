// src/components/QuestionCarousel/Modal/Modal.js

import React from 'react';

const Modal = ({ videoSrc, videoScript, onClose, title }) => {
  return (
    <div className="modal modal-open">
      <div className="modal-box relative">
        {/* Close button with X icon in the top right corner */}
        <button onClick={onClose} className="btn btn-circle btn-sm absolute right-2 top-2">
          ✕ {/* Using a plain text X as an icon */}
        </button>
        <br />
        <h2 className="flex content-center justify-center text-center mb-2 text-xl">{title}</h2>
        <video controls autoPlay className="w-full rounded-lg">
          {/* <source src={"https://drive.google.com/uc?id="+videoSrc} type="video/mp4" /> */}
          <source src={"/videos/"+videoSrc+".mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* Scrollable text container */}
        <br />
        <div className="pt-4" style={{ maxHeight: '200px', overflow: 'auto' }}>
          <p>{videoScript}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;


