import React from 'react';

const GradientBackground = ({ children, ...props }) => {
  const style = {
    background: 'linear-gradient(90deg, #000000 0%, #46278F 50%, #EF5FA7 100%)',
    // Add more styles as needed, for example:
    width: '100%',
    height: '2vh', // or '100vh' for full viewport height
    // ...other styles like padding or margin
  };

  return <div style={style} {...props}>{children}</div>;
};

export default GradientBackground;
