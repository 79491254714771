import React, { useRef, useState } from "react";
import QuestionCard from "./QuestionCard/QuestionCard.js";

function adjustTextareaHeight(textarea) {
  if (!textarea) return;
  textarea.style.height = "auto"; // Reset the height
  textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
}

const QuestionCarousel = ({
  darkMode,
  questionsData,
  questionIndex,
  setQuestionIndex,
  isLoading,
  setIsLoading,
  sliderValue,
  setSliderValue,
  isAnimating,
  setIsAnimating,
  setSliderTouched,
  sliderTouched,
  condition,
  incrementConsiderationCount
}) => {
  const keynoteTextAreaRef = useRef(null);
  const totalQuestions = questionsData.length;
  const [questionAnswered, setQuestionAnswered] = useState(false);

  return (
    <div className="flex justify-center items-center w-full bg-black animate-drawBorder">
      <div className="w-full flex flex-col items-center p-4">
        <p className="text-xl text-center text-white animate-fadeMoveUp opacity-0 animate-fill-forwards">
          As a Board Member, help us decide.
        </p>
        {questionsData.length > 0 && (
          <QuestionCard
            key={questionsData[questionIndex].id}
            data={questionsData[questionIndex]}
            questionIndex={questionIndex}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            adjustTextareaHeight={adjustTextareaHeight}
            darkMode={darkMode}
            setQuestionAnswered={setQuestionAnswered}
            isAnimating={isAnimating}
            setIsAnimating={setIsAnimating}
            setSliderTouched={setSliderTouched}
            sliderTouched={sliderTouched}
            condition={condition}
            incrementConsiderationCount={incrementConsiderationCount}
          />
        )}

        <div className="flex flex-col w-1/2 items-center mt-4">
          <progress
            className="progress progress-primary w-full"
            value={questionIndex + 1}
            max={totalQuestions}
            style={{ borderRadius: "0" }}
          ></progress>
          <p className="mt-2 text-white">
            Question {questionIndex + 1} / {totalQuestions}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionCarousel;
