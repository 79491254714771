import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import MainPage from "./MainPage.js";
import IpadPage from "./IpadPage.js";

import sketch from "./components/backgroundSketch.js";

import "./components/Header/header.css";
import "./components/Footer/footer.css";

import { ReactP5Wrapper } from "react-p5-wrapper";
import Header from "./components/Header/Header.js";

function App() {

  // const [darkMode, setDarkMode] = useState(
  //   window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  // );
  const [darkMode, setDarkMode] = useState("dark");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = () => setDarkMode(mediaQuery.matches);
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  return (
    <Router>
      <Header Link={Link} darkMode={darkMode} />

      <div>
        <Routes>
          <Route
            path="/app"
            element={
              <>
                <IpadPage
                  darkMode={darkMode}
                  character={"Sam Altman"}
                  questionSet={"updated_questions_altman_with_embeddings_and_summaries"}
                  ipadNumber={1}
                />
              </>
            }
          />
          <Route
            path="/"
            element={
              <MainPage darkMode={darkMode} sketch={sketch} Link={Link} />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
