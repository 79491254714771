import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import Modal from "../Modal/Modal.js";
import thumbnail from "./thumbnail.png"; // Ensure this path is correct
import "./questionCard.css";
import axios from "axios";

const API_KEY = "AIzaSyAZ34hj86OF0Kf5U8YDyfUSviFgbgZqZKU"; // Replace with your actual API key
const FOLDER_ID = "1RmX-s9Q5DZzPvwLsBIR-ATQwZiHrVgxK";

const QuestionCard = ({
  data,
  questionIndex,
  sliderValue,
  setSliderValue,
  updateState,
  isLoading,
  setIsLoading,
  adjustTextareaHeight,
  darkMode,
  setQuestionAnswered,
  isAnimating,
  setIsAnimating,
  setSliderTouched,
  sliderTouched,
  condition,
  incrementConsiderationCount,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideoSrc, setSelectedVideoSrc] = useState("");
  const [selectedVideoScript, setSelectedVideoScript] = useState("");
  const [selectedAnswerTitle, setSelectedAnswerTitle] = useState("");

  const [displayedScript, setDisplayedScript] = useState("");
  const textareaRef = useRef(null);
  const scriptAnimationIndex = useRef(0);
  const videoRefs = useRef([]);

  const leftPercentage = Math.round(((7 - sliderValue) / 6) * 100);
  const rightPercentage = Math.round(((sliderValue - 1) / 6) * 100);

  const [mediaURL, setMediaURL] = useState(null);

  const [highlightedVideoIndex, setHighlightedVideoIndex] = useState(null);

  const fetchMediaFile = async (filename, callback) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=name='${filename}'+and+'${FOLDER_ID}'+in+parents&key=${API_KEY}`
      );
      const data = await response.json();

      if (data.files && data.files.length > 0) {
        const fileId = data.files[0].id;
        const mediaURL = `/api/proxy?fileId=${fileId}`;
        if (callback) callback(mediaURL);
      } else {
        console.error("Media file not found:", filename);
      }
    } catch (error) {
      console.error("Error fetching media file:", error);
    }
  };

  const openModalWithVideo = (videoSrc, videoScript, answerTitle) => {
    setSelectedVideoSrc(videoSrc);
    setSelectedVideoScript(videoScript);
    setSelectedAnswerTitle(answerTitle);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSliderChange = (event) => {
    const newSliderValue = event.target.value;
    setSliderValue(newSliderValue);
  };

  // Function to animate text
  const animateText = (text) => {
    setIsAnimating(true); // Start animation and disable slider
    const words = text.split(" ");
    const animate = () => {
      if (scriptAnimationIndex.current < words.length) {
        const nextText = words
          .slice(0, scriptAnimationIndex.current + 1)
          .join(" ");
        setDisplayedScript(nextText);
        scriptAnimationIndex.current += 1;
        setTimeout(animate, 1); // Adjust the speed as needed
      } else {
        setIsAnimating(false); // End animation and enable slider
      }
    };
    animate();
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to recalculate
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  }, [displayedScript]); // This effect runs every time the displayedScript changes

  const handleSliderRelease = async (event) => {
    setSliderTouched(true); // Set slider touched to true
    incrementConsiderationCount(); // Increment the count each time the slider is released
    const newSliderValue = event.target.value;
    setSliderValue(newSliderValue);
    scriptAnimationIndex.current = 0;
    setDisplayedScript("");
    if (condition === "text") {
      animateText(data.answers[newSliderValue - 1].script);
    }

    setQuestionAnswered(true);

    let filenameBase;
    let fileExtension;

    if (condition === "talking_head") {
      filenameBase = `${questionIndex}_${
        newSliderValue - 1
      }_sam_altman_-moshed`;
      fileExtension = ".mp4";
    } else if (condition === "audio") {
      filenameBase = `${questionIndex}_${newSliderValue - 1}_sam_altman`;
      fileExtension = ".mp3";
    }

    const mediaFilename = `${filenameBase}${fileExtension}`;

    if (condition === "talking_head" || condition === "audio") {
      console.log("Fetching media file:", mediaFilename);
      await fetchMediaFile(mediaFilename, setMediaURL);
    }
  };

  useEffect(() => {
    if (condition === "semantic_map") {
      if (!d3.select("#semantic-map svg").empty()) {
        d3.select("#semantic-map svg").remove();
      }
      createSemanticMap();
    }
  }, [condition]);

  useEffect(() => {
    if (condition === "semantic_map") {
      // Update the border styles of the videos
      d3.selectAll("foreignObject")
        .select("div")
        .style("border", function (d) {
          return highlightedVideoIndex === d ? "3px solid oklch(0.6971 0.329 342.55)" : "none";
        });
    }
  }, [highlightedVideoIndex]);
  

  // Function to create a semantic map
  const createSemanticMap = () => {
    const svg = d3
      .select("#semantic-map")
      .append("svg")
      .attr("width", 800)
      .attr("height", 380);

    const dataPoints = data.answers.map((answer) => [
      answer.x_coordinate,
      answer.y_coordinate,
    ]);

    console.log("Data points:", dataPoints);

    // Assuming you have a method to get 2D PCA or t-SNE coordinates of the embeddings

    const xExtent = d3.extent(dataPoints, (d) => d[0]);
    const yExtent = d3.extent(dataPoints, (d) => d[1]);

    const xScale = d3.scaleLinear().domain(xExtent).range([60, 680]);
    const yScale = d3.scaleLinear().domain(yExtent).range([30, 300]);

    data.answers.forEach((answer, i) => {
      const filenameBase = `${questionIndex}_${i}_sam_altman_-moshed`;
      const fileExtension = ".mp4";
      const mediaFilename = `${filenameBase}${fileExtension}`;
    
      fetchMediaFile(mediaFilename, (mediaURL) => {
        // Assign unique ID to each video
        const videoId = `video-${i}`;
    
        const foreignObject = svg
          .append("foreignObject")
          .attr("x", xScale(dataPoints[i][0]) - 40)
          .attr("y", yScale(dataPoints[i][1]) - 30)
          .attr("width", 140)
          .attr("height", 110)
          .datum(i) // Attach the index as data
          .append("xhtml:div")
          .html(
            `<div style="text-align: center;">
              <video id="${videoId}" width="160" height="120" controls>
                <source src="${mediaURL}" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p style="color: white; font-size: 12px;">${answer.script_summary}</p>
            </div>`
          );
    
        // Get the video element
        const videoElement = foreignObject.select(`#${videoId}`);
    
        videoElement.on("play", () => {
          // Pause all other videos
          d3.selectAll("video").each(function () {
            if (this !== videoElement.node()) {
              this.pause();
            }
          });
          console.log("Video clicked:", i);
          setSliderValue(i + 1);
          setSliderTouched(true);
          incrementConsiderationCount();
          animateText(answer.script);
          setQuestionAnswered(true);
          setHighlightedVideoIndex(i); // Highlight this video
        });
      });
    });
    
  };

  return (
    <div
      className={`card w-full mt-0 mb-0 ${
        darkMode ? "bg-black" : "bg-white"
      } shadow-xl`}
    >
      <div className="card-body p-2">
        <h2 className="text-3xl mb-2 text-center justify-center text-secondary animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-2">
          <b>{data.question}</b>
        </h2>

        {condition === "semantic_map" && (
          <>
          <p className="text-md text-center text-white animate-fadeMoveUp opacity-0 animate-fill-forwards">
            Play a video to make your selection.</p>
          <div
            id="semantic-map"
            className="semantic-map-container flex justify-center items-center"
          ></div>
          </>
        )}

        {condition !== "semantic_map" && (
          <div className="grid grid-cols-[1fr_2fr_1fr] gap-4 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-3">
            <div className="text-center justify-center text-white">
              <p className="text-xl">{leftPercentage}%</p>
              <b>
                <p className="text-xl">{data.sliderTexts[0]}</p>
              </b>
            </div>

            {/* Slider placed in the middle column */}
            <div className="flex flex-col justify-center items-center">
              <input
                type="range"
                min="1"
                max="7"
                step="1"
                value={sliderValue}
                className="range range-secondary w-full pl-5 pr-5 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-5"
                onChange={handleSliderChange}
                onMouseUp={handleSliderRelease}
                onTouchEnd={handleSliderRelease}
                disabled={isAnimating}
              />
            </div>

            <div className="text-center justify-center text-white animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-4">
              <p className="text-xl">{rightPercentage}%</p>
              <b>
                <p className="text-xl">{data.sliderTexts[1]}</p>
              </b>
            </div>
          </div>
        )}

        {condition === "text" && sliderTouched && (
          <>
            <h2 className="text-xl mt-2 text-center justify-center text-secondary animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1">
              One possible alternate-Altman keynote script:
            </h2>
            <div className="explanation_area">
              <textarea
                ref={textareaRef}
                className="textarea textarea-bordered w-full text-lg text-white bg-black border-none"
                style={{ overflow: "visible", resize: "none" }}
                value={displayedScript}
                readOnly
              />
            </div>
          </>
        )}

        {condition === "audio" && sliderTouched && mediaURL && (
          <>
            <h2 className="text-xl mt-2 text-center justify-center text-secondary animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1">
              One possible alternate-Altman keynote script:
            </h2>
            <div className="audio-content mt-2 flex justify-center">
              <audio
                key={mediaURL}
                controls
                style={{
                  width: "100%",
                  maxWidth: "500px",
                }}
              >
                <source src={mediaURL} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </>
        )}

        {condition === "talking_head" && sliderTouched && mediaURL && (
          <>
            <h2 className="text-xl mt-2 text-center justify-center text-secondary animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1">
              One possible alternate-Altman keynote script:
            </h2>
            <div className="video-content flex justify-center">
              <video key={mediaURL} width="320" height="240" controls>
                <source src={mediaURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </>
        )}
      </div>
      {modalOpen && (
        <Modal
          videoSrc={selectedVideoSrc}
          videoScript={selectedVideoScript}
          onClose={closeModal}
          title={selectedAnswerTitle}
        />
      )}
    </div>
  );
};

export default QuestionCard;
