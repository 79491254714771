//IpadPage.js

import React, { useState, useEffect } from "react";
import QuestionCarousel from "./components/QuestionCarousel/QuestionCarousel.js";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { useLocation } from "react-router-dom";
import sketch from "./components/backgroundSketch.js";
import Footer from "./components/Footer/Footer.js";
import { v4 as uuidv4 } from "uuid";
import loadingGIF from "./components/loading.gif";
import googleSheetEndpoint from "./googleSheetEndpoint.js";
import { FFmpeg } from "@ffmpeg/ffmpeg";

const ffmpeg = new FFmpeg();

const API_KEY = "AIzaSyAZ34hj86OF0Kf5U8YDyfUSviFgbgZqZKU"; // Replace with your actual API key
const FOLDER_ID = "1RmX-s9Q5DZzPvwLsBIR-ATQwZiHrVgxK";

function IpadPage({ darkMode, character, questionSet, ipadNumber }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const condition = queryParams.get("condition") || "text"; // Defaults to 'text' if not specified
  const code = queryParams.get("code") || "0"; // Defaults to 'text' if not specified

  const [currentPage, setCurrentPage] = useState(1);
  const [userText, setUserText] = useState(""); // To store user's input from page 4
  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // New state to track if animation is happening
  const [userDemographic, setUserDemographic] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userID, setUserID] = useState(uuidv4());
  const [sliderValue, setSliderValue] = useState(4);
  const [sliderTouched, setSliderTouched] = useState(false);
  const [responses, setResponses] = useState([]);
  const [shouldProceed, setShouldProceed] = useState(false);
  const [videoFileNames, setVideoFileNames] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileLinks, setFileLinks] = useState([]);
  const [videoReady, setVideoReady] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [considerationCount, setConsiderationCount] = useState(0);

  const incrementConsiderationCount = () => {
    setConsiderationCount((prevCount) => prevCount + 1);
  };

  // const loadFFmpeg = async () => {
  //   if (!ffmpeg.loaded) {
  //     console.log("awaiting ffmpeg load...");
  //     await ffmpeg.load();
  //   }
  // };

  // useEffect(() => {
  //   loadFFmpeg();
  // }, []);

  useEffect(() => {
    if (currentPage === 3) {
      setQuestionStartTime(Date.now());
      setConsiderationCount(0);
    }
  }, [currentPage, questionIndex]);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      setIsLoading(true);
      try {
        let path = questionSet + ".json";
        const response = await fetch(path); // Assuming 'questionSet' is a valid path
        const data = await response.json();
        setQuestionsData(data);
      } catch (error) {
        console.error("Failed to fetch questions data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionsData();
  }, [questionSet]); // Rerun this effect if 'questionSet' changes

  const resetSurvey = () => {
    setCurrentPage(1);
    setQuestionIndex(0);
    setUserID(uuidv4());
    setResponses([]);
    setUserDemographic("");
    setSliderValue(4);
    setSliderTouched(false);
    setVideoFileNames([]);
    setVideoSrc(null);
    setIsProcessing(false);
    setFileLinks([]);
    setVideoReady(false);
    setUserText("");
    setShouldProceed(false);
    // Reset any other state variables you have added
  };

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);

  const handleDemographicChoice = (choice) => {
    setUserDemographic(choice);
    nextPage();
  };

  const handleTextSubmit = (text) => {
    const timeSpent = Date.now() - questionStartTime; // Calculate time spent on the question
    setUserText(text);
    const newResponses = [...responses];
    newResponses[questionIndex] = {
      sliderValue,
      openResponse: text,
      time_spent: timeSpent,
      considerations: considerationCount,
    };
    setResponses(newResponses);
    const newVideoFileNames = [...videoFileNames];
    newVideoFileNames[questionIndex] = `${questionIndex}_${
      sliderValue - 1
    }_sam_altman_-moshed.mp4`;
    setVideoFileNames(newVideoFileNames);
    setShouldProceed(true); // Set shouldProceed to true
  };

  useEffect(() => {
    if (shouldProceed) {
      handleCarouselNext();
      setShouldProceed(false); // Reset shouldProceed
    }
  }, [shouldProceed, responses]); // Watch for changes to shouldProceed and responses

  const onVideoEndIpad = () => {
    resetSurvey();
  };

  const handleSliderToOpenText = () => {
    //setCurrentPage(4); //bypass page4
    handleTextSubmit("text_input_bypassed");
  };

  const handleCarouselNext = () => {
    console.log(videoFileNames);
    if (questionIndex < questionsData.length - 1) {
      setCurrentPage(3); // Ensure the user stays on the carousel page until all questions are answered
      setQuestionIndex(questionIndex + 1);
      setUserText(""); // Reset user's text input
      setSliderValue(4); // Reset slider value
      setSliderTouched(false); // Reset sliderTouched
      setConsiderationCount(0);
      setQuestionStartTime(Date.now());
    } else {
      sendToGoogleSheets();
      setCurrentPage(5);
    }
  };

  // useEffect(() => {
  //   const loadAndFetchFiles = async () => {
  //     if (currentPage === 5) {
  //       await loadFFmpeg();
  //       await fetchFilesInFolder();
  //     }
  //   };

  //   loadAndFetchFiles();
  // }, [currentPage]);

  // useEffect(() => {
  //   let timeout;
  //   if (currentPage === 5) {
  //     timeout = setTimeout(() => {
  //       resetSurvey();
  //     }, 5000); // 5000 milliseconds = 5 seconds
  //   }
  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //   };
  // }, [currentPage]);

    useEffect(() => {
    if (currentPage === 5) {
      //Pass message to parent for qualtrics completion
      window.parent.postMessage("appComplete", "*");
    }
  }, [currentPage]);

  // useEffect(() => {
  //   if (fileLinks.length > 0) {
  //     handleUpload();
  //     setVideoReady(true);
  //   }
  // }, [fileLinks]);

  useEffect(() => {
    if (videoReady) {
      setTimeout(() => {
        setCurrentPage(6);
      }, 3000); // 3 seconds to move to "look up" page
    }
  }, [videoSrc]);

  const sendToGoogleSheets = () => {
    let body = {
      dateTime: new Date().toLocaleString(),
      userID,
      ipadNumber,
      demographic: userDemographic,
      condition: condition,
      code: code,
    };

    responses.forEach((response, index) => {
      body[`sliderValue${index + 1}`] = response.sliderValue;
      body[`openResponse${index + 1}`] = response.openResponse;
      body[`timeSpent${index + 1}`] = response.time_spent;
      body[`considerations${index + 1}`] = response.considerations;
    });

    console.log("Data to send to Google Sheets:", body);

    fetch(googleSheetEndpoint, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(body),
    })
      .then((response) => response.text())
      .then((result) => console.log("Data sent successfully to Google Sheets"))
      .catch((error) =>
        console.error("Error sending data to Google Sheets:", error)
      );
  };

  // const fetchFilesInFolder = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://www.googleapis.com/drive/v3/files?q='${FOLDER_ID}'+in+parents&key=${API_KEY}`
  //     );
  //     const data = await response.json();
  //     const requiredFiles = videoFileNames;
  //     const links = data.files
  //       .filter((file) => requiredFiles.includes(file.name))
  //       .map((file) => ({
  //         name: file.name,
  //         link: `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media&key=${API_KEY}`,
  //         id: file.id,
  //       }));
  //     setFileLinks(links); // Set state with the fetched links
  //   } catch (error) {
  //     console.error("Error fetching files:", error);
  //   }
  // };

  // const handleUpload = async () => {
  //   setIsProcessing(true);
  //   console.log("handling upload");

  //   try {
  //     console.log("fileLinks", fileLinks);

  //     // Sort fileLinks based on the numerical prefix in the file names
  //     const sortedFileLinks = fileLinks.sort((a, b) => {
  //       const aNumber = parseInt(a.name.split("_")[0], 10);
  //       const bNumber = parseInt(b.name.split("_")[0], 10);
  //       return aNumber - bNumber;
  //     });

  //     console.log("sortedFileLinks", sortedFileLinks);

  //     const videoBuffers = await Promise.all(
  //       sortedFileLinks.map((file) => fetchGoogleDriveFile(file.id))
  //     );

  //     console.log("videoBuffers", videoBuffers);

  //     if (videoBuffers.some((buffer) => buffer.byteLength === 0)) {
  //       throw new Error(
  //         "Some video buffers are empty, indicating a problem with file fetching."
  //       );
  //     }

  //     await Promise.all(
  //       videoBuffers.map((buffer, index) => {
  //         const fileName = `video${index + 1}.mp4`;
  //         console.log(`Writing file: ${fileName}`);
  //         return ffmpeg.writeFile(fileName, new Uint8Array(buffer));
  //       })
  //     );

  //     const concatList = sortedFileLinks
  //       .map((_, index) => `file 'video${index + 1}.mp4'`)
  //       .join("\n");
  //     console.log("concatList", concatList);

  //     await ffmpeg.writeFile("concat.txt", concatList);

  //     await ffmpeg.exec([
  //       "-f",
  //       "concat",
  //       "-safe",
  //       "0",
  //       "-i",
  //       "concat.txt",
  //       "-c",
  //       "copy",
  //       "output.mp4",
  //     ]);

  //     const data = await ffmpeg.readFile("output.mp4");

  //     console.log("data", data);
  //     const videoBlob = new Blob([data.buffer], { type: "video/mp4" });
  //     const videoUrl = URL.createObjectURL(videoBlob);
  //     setVideoSrc(videoUrl);
  //   } catch (error) {
  //     console.error("Error processing videos:", error);
  //   }

  //   setIsProcessing(false);
  // };

  // Proxy Setup - Need to switch to NEXT for this
  // const fetchGoogleDriveFile = async (fileId) => {
  //   try {
  //     const response = await fetch(`/api/proxy?fileId=${fileId}`);
  //     if (!response.ok) throw new Error("Network response was not ok");
  //     const data = await response.arrayBuffer();
  //     console.log(`Fetched file ${fileId}, size: ${data.byteLength} bytes`);
  //     return data;
  //   } catch (error) {
  //     console.error(`Error fetching file with ID ${fileId}:`, error);
  //     throw error;
  //   }
  // };

  // const delay = (time) => {
  //   return new Promise((resolve) => setTimeout(resolve, time));
  // };

  // const showVideo = () => {
  //   var element = document.getElementById("videoIpad");
  //   var button = document.getElementById("buttonIpad");
  //   element.style.display = "block"; // Ensure the video is visible
  //   button.style.display = "none"; // Hide the button after it's clicked
  //   delay(100).then(() => {
  //     var video = document.getElementById("videoIpad");
  //     if (video.paused) {
  //       video
  //         .play()
  //         .catch((error) =>
  //           console.error("Error attempting to play the video:", error)
  //         );
  //     }
  //   });
  // };

  return (
    <div className={`App ${darkMode ? "dark-mode" : ""}`}>
      <div className="ReactP5Wrapper">
        <ReactP5Wrapper sketch={sketch(darkMode)} />
      </div>

      {currentPage === 1 && (
        <div className="card w-4/4 mx-auto text-center">
          <div className="card-body m-8 mx-64 flex flex-col justify-center items-center py-4">
            <h2 className="text-6xl text-white font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards">
              Congratulations!
            </h2>
            <h2 className="text-3xl m-8 text-white font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1 ">
              You are invited to be a board member of OpenOpenAI.
            </h2>
            <h2 className="text-xl text-white animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1">
              Help us imagine the future of artificial intelligence through dialogue
              with "Alternate Altmans"—AI personas embodying diverse ethical
              visions. Cast your votes and witness the simulated consequences of
              your decisions as we reimagine AI governance and democratize the
              trajectory of technological evolution.
            </h2>

            <button
              onClick={nextPage}
              className="mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-delay-2 animate-fadeMoveUp opacity-0 animate-fill-forwards"
            >
              Tap to Begin
            </button>
          </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="card w-4/4 mx-32 mx-auto text-center">
          <div className="card-body flex flex-col justify-center items-center py-4">
            <h2 className="text-3xl m-4 text-white font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-3">
              Tell us more about yourself...
            </h2>
            <div className="flex justify-center items-center space-x-4 mt-4">
              <button
                onClick={() => handleDemographicChoice("frequently")}
                className="w-48 h-48 mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-4"
              >
                I use AI tools <b>frequently</b>
              </button>
              <button
                onClick={() => handleDemographicChoice("sometimes")}
                className="w-48 h-48 mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-5"
              >
                I use AI tools <b>sometimes</b>
              </button>
              <button
                onClick={() => handleDemographicChoice("never")}
                className="w-48 h-48 mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-6"
              >
                I <b>never</b> use AI tools
              </button>
            </div>
          </div>
        </div>
      )}

      {currentPage === 3 && (
        <div className="px-16 py-4 flex flex-col justify-center items-top">
          <QuestionCarousel
            questionIndex={questionIndex} // Pass the index of the question to display
            setQuestionIndex={setQuestionIndex} // Function to update the question index
            darkMode={darkMode}
            questionSet={questionSet}
            questionsData={questionsData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            isAnimating={isAnimating}
            setIsAnimating={setIsAnimating}
            setSliderTouched={setSliderTouched}
            sliderTouched={sliderTouched}
            condition={condition}
            incrementConsiderationCount={incrementConsiderationCount}
          />
          <center>
            <button
              onClick={handleSliderToOpenText}
              disabled={sliderTouched === false || isAnimating}
              className={`w-48 h-24 mt-2 p-8 text-2xl border border-primary ${
                sliderTouched === false || isAnimating
                  ? "hidden"
                  : "bg-black hover:bg-blue-700 transition duration-300"
              } animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1 text-white`}
            >
              Next
            </button>
          </center>
        </div>
      )}

      {/* {currentPage === 4 && (
        <div className="px-16 py-4 flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-full bg-black border border-primary">
            <div className="card-body flex flex-col justify-center items-center py-4">
              <p className="text-3xl mb-0 text-center text-white">
                As a Board Member, help us decide.
              </p>
              <h2 className="text-3xl mt-0 text-secondary font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-0">
                Please explain your reason?
              </h2>
              <textarea
                onChange={(e) => setUserText(e.target.value)}
                className="border text-2xl p-2 text-white border-primary bg-black animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1"
                style={{ width: "50vw", height: "30vh" }}
                value={userText} // Bind the textarea value to userText
              />
              <button
                onClick={() => handleTextSubmit(userText)}
                className="w-48 h-24 mt-8 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}

      {currentPage === 5 && (
        <div className="card w-4/4 mx-auto text-center">
          <div className="card-body flex flex-col justify-center items-center py-4 m-32">
            <h1 className="text-6xl text-white font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards">
              Thank you!
            </h1>

            <h2 className="text-3xl text-white m-5 text-secondary font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-2">
              We value your contribution
            </h2>

            {/* <button
              onClick={resetSurvey}
              className="mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-delay-2 animate-fadeMoveUp opacity-0 animate-fill-forwards"
            >
              Restart
            </button> */}

            {/* <h2 className="text-3xl m-5 text-secondary font-bold animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-8">
              Let’s see what that future might look like at OpenAI's 2024
              Keynote...
            </h2>

            <img src={loadingGIF} alt="Loading" width={100} height={100} /> */}
          </div>
        </div>
      )}

      {/* {currentPage === 6 && (
        <div className="card w-full mx-auto text-center">
          <div className="card-body w-full flex flex-col justify-center items-center py-4">
            <center>
              <video
                id="videoIpad"
                src={videoSrc}
                width="1000px"
                className="animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1"
                onEnded={onVideoEndIpad}
              ></video>
              <div className="flex justify-center items-center space-x-4 mt-2">
                <button
                  type="button"
                  onClick={showVideo}
                  id="buttonIpad"
                  className="text-white text-4xl bg-black p-8 mt-8 m-50 border border-primary"
                >
                  Play
                </button>
              </div>
            </center>
          </div>
        </div>
      )} */}

      <Footer darkMode={darkMode} />
    </div>
  );
}

export default IpadPage;
