import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import './index.css';
import App from './App.js';

// Use createRoot to create a root.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app using the root.render method.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


