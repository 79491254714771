//MainPage.js
import React, { useState, useEffect } from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import Footer from "./components/Footer/Footer.js";

function MainPage({
  darkMode,
  sketch,
  Link,
}) {

  const [videoSrc, setVideoSrc] = useState("/videos/new_placeholder_2.mp4");

  return (
    <div className="App">
      <div className="ReactP5Wrapper">
        <ReactP5Wrapper sketch={sketch(darkMode)} />
      </div>

      <div className="content-container m-8">
        <div className="w-full flex flex-col items-center text-center">
          <h2 className="text-xl text-white animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1">
          Welcome to OpenOpenAI, where you become a board member shaping the future of artificial intelligence through dialogue with "Alternate Altmans"—AI personas embodying diverse ethical visions. Cast your votes and witness the simulated consequences of your decisions as we reimagine AI governance and democratize the trajectory of technological evolution.
          </h2>
          <video
            id="video"
            src={videoSrc}
            width="40%"
            className="m-8 animate-fadeMoveUp opacity-0 animate-fill-forwards animate-delay-1"
            autoPlay
            muted
            // onEnded={onVideoEnd} // Handle video end to switch to placeholder
            // loop={loopState}
            loop={true}
          ></video>
          <Link to="/app">
            <button>
              <div className="mt-4 p-8 text-2xl bg-black border border-primary text-white hover:bg-blue-700 transition duration-300 animate-delay-2 animate-fadeMoveUp opacity-0 animate-fill-forwards">
                Launch App
              </div>
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;
