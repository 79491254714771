// src/components/Header/Header.js

import React from "react";
import openOpenAIlogoLight from "./openopenai-white.png";
import openOpenAIlogoDark from "./openopenai-black.png";
// import medialablogoLight from "./medialab-light.png";
// import medialablogoDark from "./medialab-dark.png";
// import mmlogowhite from "./mm_logo_white.png";
// import mmlogoblack from "./mm_logo_black.png";
import "./header.css";
import GradientBackground from "../GradientBackground/gradientBackground.js";

const Header = ({ Link, darkMode }) => {
  return (
    <>
      <GradientBackground />
      <div className="logos">
        <h1 className="text-3xl font-bold text-white"></h1>
        {/* <div className="logo-left">
          <Link to="/">
            <img
              // src={darkMode ? logoLight : logoDark}
              src={darkMode ? mmlogowhite: mmlogoblack}
              alt="OpenOpenAI Logo"
              className="logo"
              style={{ width: "auto", height: "100px" }}
            />
          </Link>
        </div> */}
        <div className="logo-center flex justify-center items-center">
          <Link to="https://media.mit.edu" target="_blank">
            <img
              src={darkMode ? openOpenAIlogoLight : openOpenAIlogoDark}
              alt="OpenOpenAI Logo"
              className="logo"
              style={{ height: "40px", marginTop: "0px" }}
            />
          </Link>
        </div>

        {/* <div className="logo-right">
      <Link to="https://media.mit.edu" target="_blank" >
        <img
          src={darkMode ? medialablogoLight : medialablogoDark}
          alt="Media Lab Logo"
          className="logo"
          style={{ width: "auto", height: "70px", marginTop: "10px"}}
        />
        </Link>
      </div> */}
      </div>
    </>
  );
};

export default Header;
