// src/components/Footer/Footer.js
import React from "react";
import "./footer.css";

import mitmedialabgrouplogo from "./mitmedialab_logo.png";

const Footer = () => {
  return (
<footer class="footer items-center">
<aside class="items-left grid-flow-col flex flex-col">
  <p className="text-xl">#OpenOpenAI</p>
  <p className="text-xl">#MITOpenOpenAI</p>
  <p className="text-xl">#MITMediaLab</p>
</aside>
  <nav class="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
  <img
          src={mitmedialabgrouplogo}
          alt="Media Lab Logo"
          className="logo"
          style={{ width: "auto", height: "50px", marginTop: "10px"}}
        />
   </nav>
</footer>
  );
};

export default Footer;